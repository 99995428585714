import styles from '../../styles/Home.module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import icon from '../../public/favicon.ico'
import { useRouter } from 'next/router';
import api from '../api/Apis'
import { useEffect, useState } from 'react';
import Home from './Components/Home/Home'

export default function Index({ setshowloader, setshowminicart, minCartAutoshipCheck, smartShipCheck, setcounts, setInnerLoader }) {
  const route = useRouter();
  const store = "us";
  const [homePageData, sethomePageData] = useState();
  useEffect(() => {
    // setshowloader(true)
    // setInnerLoader(true)
    document.body.classList.remove('bg-salmon');
    api.getHomepageContent(store).then(res => {
      if (res?.status === 200) {
        sethomePageData(res?.data)
        setTimeout(() => {
          setshowloader(false)
          setInnerLoader(false)
        }, 4000);
      }
    })
  }, []);

  return <Home styles={styles} icon={icon} homePageData={homePageData} setshowloader={setshowloader} smartShipCheck={smartShipCheck} setInnerLoader={setInnerLoader}
    setshowminicart={setshowminicart} minCartAutoshipCheck={minCartAutoshipCheck} setcounts={setcounts} />
}
